
.el-color-picker__color[data-v-9c71d40a] {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 2px;
  width: 100px;
  height: 30px;
  float: left;
  margin-right: 2px;
}
